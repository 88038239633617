import React from 'react'
import ReactDOM from 'react-dom'
import { isBuilt } from '../../utilities/isBuilt'
import { getOptionalRuntimeEnvs } from '../../env/getEnvs'

export async function initReactAxe() {
  if (typeof window !== 'undefined' && !isBuilt && getOptionalRuntimeEnvs().NEXT_PUBLIC_TEST_A11Y) {
    const axe = require('@axe-core/react')
    // !! Axe does not work with react 18 so i had to change this to a require to clear an error and stop using the
    // interval argument they provide and instead just recall axe every second. Very possible this will cause performance
    // issues or a memory leak, but it only shows up locally, so if that occurs, we can just remove this file.
    setInterval(() => {
      axe(React, ReactDOM)
    }, 1000)
  }
}
