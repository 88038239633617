import { Operation, split } from '@apollo/client'
import { clientNameContextKey } from '../../../common/constants/apolloContextKeys'
import { httpLink, relayLink, franchiseLink } from './httpLinks'
import { makeSubscriptionLink } from './makeSubscriptionLink'
import { relayClient, franchiseClient } from '../../../common/constants/apolloContextClientNames'
import { isSubscriptionOperation } from '@apollo/client/utilities'

// TODO need to work on our server side auth. currently, all of this is reliant on client side data. need to add cookie
// etc and use this in place of localstorage on server

const isRelay = (operation: Operation) => operation.getContext()[clientNameContextKey] === relayClient
const isFranchise = (operation: Operation) => operation.getContext()[clientNameContextKey] === franchiseClient

export const queryLinks = split(
  ({ query }) => isSubscriptionOperation(query),
  makeSubscriptionLink(),
  split(
    (operation) => isRelay(operation),
    relayLink,
    split((operation) => isFranchise(operation), franchiseLink, httpLink)
  )
)
