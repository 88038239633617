import styled from '@emotion/styled'
import { AdjustableWeightHiddenPsuedoText } from '../../../common/style/AdjustableWeightHiddenPsuedoText'
import { transientOptions } from '../../../common/style/transientOptions'
import { theme } from '../../../styles/theme'

const ItemContainer = styled(AdjustableWeightHiddenPsuedoText, transientOptions)<{ $isActive: boolean }>`
  color: ${(p) => (p.$isActive ? theme.colors.grayscalewhite : theme.colors.primary100)};
  text-transform: uppercase;
  font-size: 14px;
  font-family: ${theme.fonts.ibmPlexMono};
  font-weight: ${(p) => (p.$isActive ? 700 : 600)};
  letter-spacing: 0.84px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  > * {
    font-family: ${theme.fonts.ibmPlexMono};
  }

  :hover:not(:disabled) {
    color: ${theme.colors.grayscalewhite};
  }
`

export const LeftSideItemContainer = styled(ItemContainer)`
  padding: 8px 9px;
  :first-of-type {
    margin-left: 2px;
  }
`
export const RightSideItemContainer = styled(ItemContainer)`
  width: 40px;
`
