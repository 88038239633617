import * as React from 'react'
import { isInLogInProcessCookieName } from '../../../common/constants/cookies'
import { useCookies } from '../../../hooks/useCookies'
import { useAccount } from '../../auth/hooks/useAccount'
import { useLogInState } from '../../auth/authState'
import { LazyLoadingSpinner } from '../../../common/components/LazyLoadingSpinner'

export function useAppLoadingSpinnerJsx() {
  const { cookies, remove } = useCookies()

  const [{ postAuthRedirectInProgress }] = useLogInState()
  const showSpinner = cookies[isInLogInProcessCookieName] === 'true' || postAuthRedirectInProgress

  const { isLoading } = useAccount()

  React.useEffect(() => {
    if (!isLoading) remove(isInLogInProcessCookieName)
  }, [isLoading, remove])
  return showSpinner ? <LazyLoadingSpinner styles={{ justifySelf: 'center', flex: 1 }} delay={0} /> : null
}
