import * as React from 'react'
import styled from '@emotion/styled'
import { notebookDownMq } from '../../../styles/mediaQueries'
import { theme } from '../../../styles/theme'
import { reommendationsScreenUrl, surveyScreenUrl } from '../../recommendations/common'
import { cdnUrl } from '../../../common/constants/s3ImageUrls'
import { NextImage } from '../../../common/functionality/NextImage'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'
import { NextLink } from '../../../common/functionality/NextLink'

/* eslint-disable regex/invalid-error, no-restricted-imports */
import ClipboardPng from '../assets/clipboard.png'
import TestTubePng from '../assets/test-tube.png'
import WearablePng from '../assets/wearable.png'
import SpeedometerPng from '../assets/speedometer.png'
import clsx from 'clsx'
import { Display } from '../../../common/style/Display'
import { css } from '@emotion/react'
import { dataSourcesRoute, shopLabsPackagesRoute } from '../../../common/constants/routes'
import { useIsCanada } from '../../account/features/franchise-membership/hooks/useHost/useIsCanada'
import { transientOptions } from '../../../common/style/transientOptions'
/* eslint-enable regex/invalid-error, no-restricted-imports */

const Container = styled('div', transientOptions)<{ $isCanada: boolean }>`
  overflow: hidden;
  height: ${(p) => (p.$isCanada ? 526 : 566)}px;
  background-color: ${theme.colors.primary900};
  position: relative;
  display: flex;
  flex-direction: column;

  ${notebookDownMq} {
    height: unset;
    padding: 40.45px 32px 40.45px;
    min-height: ${(p) => (p.$isCanada ? 542 : 831.45)}px;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex: 1;

  ${notebookDownMq} {
    justify-content: flex-start;
    flex-direction: column;
  }
`
const HeaderTextContent = styled.div`
  margin-left: 6vw;
  margin-right: 37px;
  display: flex;
  flex-direction: column;
  max-width: 507px;
  width: 50%;
  padding-top: 75px;
  min-width: 507px;

  ${notebookDownMq} {
    margin-left: 0;
    padding-top: 0;
    width: 100%;
    min-width: unset;
    margin-right: 0;
  }
`

const desktopImageUrl = cdnUrl('/home-page/home-page-collage-desktop.webp')
const mobileImageUrl = cdnUrl('/home-page/home-page-collage-mobile.webp')
const bgImageUrl = cdnUrl('general/home-page-hero-background-leaves.png')

const HeroBackground = styled(NextImage)`
  background: ${theme.colors.grayscale900};
  z-index: 0;
  position: relative;
`

type Props = { showSurveyCompletedUi: boolean }

const makeItems = (isCanada: boolean) => [
  {
    image: ClipboardPng,
    text: ' to determine your health and wellness goals.',
    alt: 'clipboard',
    link: { href: surveyScreenUrl, text: 'Take our survey' },
  },
  ...(isCanada
    ? []
    : [
        {
          image: TestTubePng,
          text: ' to get a baseline read to best determine your course of action.',
          alt: 'test-tube',
          link: { href: shopLabsPackagesRoute, text: 'Get your lab tests' },
        },
      ]),
  {
    image: WearablePng,
    text: ' to get precise indicators on your daily health.',
    alt: 'wearable',
    link: { href: dataSourcesRoute, text: 'Integrate your wearable data' },
  },
  {
    image: SpeedometerPng,
    text: ' on how to make progress through tracking, lifestyle adjustments, and supplementation.',
    alt: 'speedometer',
    link: { href: reommendationsScreenUrl, text: 'Receive recommendations' },
  },
]

const ImageContainer = styled.div`
  position: relative;
  flex: 1;
`

export function HomePageBanner({ showSurveyCompletedUi }: Props) {
  const ctaText = showSurveyCompletedUi ? 'Connect Devices' : 'Take Survey'
  const ctaHref = showSurveyCompletedUi ? dataSourcesRoute : surveyScreenUrl

  const isCanada = useIsCanada()
  const items = React.useMemo(() => makeItems(isCanada), [isCanada])
  return (
    <Container $isCanada={isCanada}>
      <HeroBackground src={bgImageUrl} fill alt="hero-leaves-background" priority />
      <Content>
        <HeaderTextContent>
          <div className="mb-[285px] font-ogg text-5xl font-light leading-[56px] text-grayscale00 lg:mb-[35px]">
            Achieve your goals.
          </div>

          <Display isNotebookDown>
            <div className="pointer-events-none absolute h-[500px] w-full translate-y-[-76%]">
              <NextImage src={mobileImageUrl} alt="home-page-collage" fill style={{ objectFit: 'contain' }} priority />
            </div>
          </Display>

          <div className="flex flex-col gap-[30px] pl-6">
            {items.map((x, i) => (
              <div
                // it is not working putting this in a tailwind ml-[...] class. no idea why but just forcing it with vanilla css
                css={css`
                  margin-left: ${32 * i}px;

                  ${notebookDownMq} {
                    margin-left: 0;
                  }
                `}
                className={clsx(`flex items-center gap-3`)}
                key={x.alt}
              >
                <NextImage src={x.image} alt={x.alt} width={42} height={42} />
                <div className="font-ibmPlexMono text-sm font-light text-grayscale000">
                  <NextLink
                    href={x.link.href}
                    className="font-ibmPlexMono font-medium !text-primary450 !underline transition hover:!text-primary300"
                  >
                    {x.link.text}
                  </NextLink>
                  {x.text}
                </div>
              </div>
            ))}
          </div>
          <NextLink
            href={ctaHref}
            css={css`
              margin-left: 180px;
              ${notebookDownMq} {
                margin-left: 0;
                align-self: center;
              }
            `}
            className={clsx(`mt-[36px]`)}
          >
            <button className="flex h-[52px] w-[218px] items-center justify-center gap-0.5 rounded-full bg-primary700 px-5 py-4 transition hover:bg-primary800">
              <div className="text-base font-medium tracking-[0.16px] text-grayscalewhite">{ctaText}</div>
              <NavigationArrow orientation="right" color={theme.colors.grayscalewhite} size={18} />
            </button>
          </NextLink>
        </HeaderTextContent>
        <ImageContainer
          css={css`
            pointer-events: none;
            ${notebookDownMq} {
              display: none;
            }
          `}
        >
          <NextImage src={desktopImageUrl} alt="home-page-collage" fill style={{ objectFit: 'contain' }} priority />
        </ImageContainer>
      </Content>
    </Container>
  )
}
