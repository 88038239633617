'use client'
import * as React from 'react'
import { devToolsState } from '../features/admin/dev-tools/devToolsState'
import { airplaneModeSessionStorageKey } from '../common/constants/sessionStorage'
import { useAtom } from 'jotai'

export function useDevTools() {
  const [devTools, setDevTools] = useAtom(devToolsState)

  React.useEffect(() => {
    sessionStorage.setItem(airplaneModeSessionStorageKey, String(devTools.airplaneMode))
  }, [devTools.airplaneMode])
  return [devTools, setDevTools] as const
}
