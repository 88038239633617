import { from, NormalizedCacheObject } from '@apollo/client'
import { inMemoryCacheOptions } from './inMemoryCacheOptions'
import { authLink } from './links/authLink'
import { networkStatusLink } from './links/networkStatusLink'
import { ApolloClient, InMemoryCache } from '@apollo/experimental-nextjs-app-support'

import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { isLocalDev } from '../../env/utils/isLocalDev'
import { queryLinks } from './links/queryLinks'

if (isLocalDev) {
  // Adds messages only in a dev environment (no need to follow a url to see full error messages)
  loadDevMessages()
  loadErrorMessages()
}

export type MakeApolloOptions = {
  initialState?: NormalizedCacheObject
  cache?: InMemoryCache
  airplaneMode?: boolean
}

export type GetMakeApollo = typeof getMakeApollo
export type MakeApollo = ReturnType<GetMakeApollo>
export type MakeApolloReturn = ReturnType<MakeApollo>

export function getMakeApollo({
  initialState,
  cache = new InMemoryCache(inMemoryCacheOptions),
  airplaneMode,
}: MakeApolloOptions = {}) {
  return () => {
    const restoredCache = cache.restore(initialState ?? {})
    return new ApolloClient({
      link: from([networkStatusLink, authLink, queryLinks]),
      ssrMode: typeof window === 'undefined',
      cache: restoredCache,
      ...(airplaneMode && {
        defaultOptions: {
          query: {
            fetchPolicy: 'cache-only',
          },
        },
      }),
    })
  }
}
