import * as React from 'react'
import { gql } from '@apollo/client'
import styled from '@emotion/styled'
import { transientOptions } from '../../../../common/style/transientOptions'
import { theme } from '../../../../styles/theme'
import { tabletDownMq } from '../../../../styles/mediaQueries'
import { GetFeaturedPackagesQuery, useGetFeaturedPackagesSuspenseQuery } from '../../../../generated/graphql'
import { getPrices } from '../../../shop/features/labs/hooks/useLabProducts'
import { MarkerCountPill } from '../../../shop/features/labs/atoms/MarkerCountPill'
import { typedFalsyFilter } from '../../../../typescript/guards/typedFalsyFilter'
import { getPackageColor } from '../../../shop/features/labs/utils/getPackageColor'
import { PlanCardButtons } from '../../../shop/features/labs/atoms/PlanCardButtons'
import { NextLink } from '../../../../common/functionality/NextLink'
import {
  makeRouteFromDynamicSegmentSignature,
  shopLabsPackageDynamicSignature,
  shopLabsPackagesRoute,
} from '../../../../common/constants/routes'
import { LightGreenRoundLinkWithArrow } from '../../atoms/LightGreenRoundLinkWithArrow'
import { useCountryAvailability } from '../../../../app/shop/hooks/useCountryAvailability'
import { useIsMounted } from '../../../../hooks/useIsMounted'

gql`
  fragment ProductCatalogFields on stripe_product {
    slug
    id
    name
    ...StripeProductPriceFields
  }

  query GetFeaturedPackages($countryAvailability: _text!) {
    advanced: product_catalog(
      where: { name: { _ilike: "%advanced%" }, country_availability: { _contains: $countryAvailability } }
    ) {
      ...ProductCatalogFields
    }
    foundation: product_catalog(
      where: { name: { _ilike: "%foundation%" }, country_availability: { _contains: $countryAvailability } }
    ) {
      ...ProductCatalogFields
    }
  }
`

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const PackageHeaderContainer = styled.div`
  padding: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`

const PackageName = styled(NextLink)`
  color: ${theme.colors.grayscale700};
  margin-bottom: 12px;
  font-family: ${theme.fonts.ogg};
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
  letter-spacing: 0.72px;
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 24px 0;
`

const NonMemberPrice = styled.div`
  color: ${theme.colors.grayscale700};
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 4px;
`

const MemberPrice = styled.div`
  color: ${theme.colors.grayscale700};
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.3px;
`

const DualActionItemContainer = styled('div', transientOptions)`
  padding: 16px;
  border-radius: 12px;
  background: ${theme.colors.grayscalewhite};
  margin-top: 32px;
  display: flex;
  gap: 16px;
  width: 100%;

  ${tabletDownMq} {
    flex-direction: column;
  }
`

const Card = styled.div`
  width: fill-available;
  border-radius: 12px;
  min-height: 276px;
  display: flex;
  padding: 16px;
  flex-direction: column;
  align-items: center;

  ${tabletDownMq} {
    padding: 8px;
  }
`

const buttonStyles = {
  minWidth: 170,
  width: 'fit-content',
  alignSelf: 'center',
}

type Props = { index: number; product: NonNullable<ReturnType<typeof processProduct>>; isHomePage?: boolean }
function FeaturedPackage({ product, index, isHomePage }: Props) {
  const isMounted = useIsMounted()
  const colors = getPackageColor(product.name, index, isMounted, isHomePage)
  const textColor = { style: { color: colors.text } }

  const packagePdpUrl = makeRouteFromDynamicSegmentSignature(shopLabsPackageDynamicSignature, product.slug ?? '')
  if (!product.slug) return null
  return (
    <Card key={product.id} style={{ background: colors.background }}>
      <PackageHeaderContainer>
        <PackageName {...textColor} href={packagePdpUrl}>
          {product.name}
        </PackageName>
        <MarkerCountPill packageName={product.name} colors={colors} />
      </PackageHeaderContainer>
      <PriceContainer>
        <NonMemberPrice {...textColor}>{product.prices?.freeMember.display} Retail</NonMemberPrice>
        <MemberPrice {...textColor}>{product.prices?.paidMember.display} Member</MemberPrice>
      </PriceContainer>
      <PlanCardButtons
        ctaDefaultText="Add to Cart"
        colors={colors}
        productId={product.id}
        slug={product.slug}
        name={product.name}
        styles={{ mainButton: buttonStyles, viewDetails: buttonStyles }}
      />
    </Card>
  )
}

function processProduct(product?: GetFeaturedPackagesQuery['advanced'][0]) {
  if (!product) return null
  return {
    slug: product.slug,
    id: product.id,
    name: product.name,
    prices: getPrices(product),
  }
}

export function HomePageFeaturedPackagesSection() {
  const { countryAvailability } = useCountryAvailability()
  const { data } = useGetFeaturedPackagesSuspenseQuery({ variables: { countryAvailability } })

  const { advanced, foundation } = React.useMemo(() => {
    return {
      foundation: processProduct(data?.foundation[0]),
      advanced: processProduct(data?.advanced[0]),
    }
  }, [data])

  return (
    <Container>
      <DualActionItemContainer>
        {[foundation, advanced].filter(typedFalsyFilter).map((x, i) => {
          return <FeaturedPackage key={x.id} product={x} index={i} isHomePage />
        })}
      </DualActionItemContainer>

      <LightGreenRoundLinkWithArrow href={shopLabsPackagesRoute}>See All Lab Packages</LightGreenRoundLinkWithArrow>
    </Container>
  )
}
