import isNil from 'lodash/isNil'
import getConfig from 'next/config'
import { airplaneModeParamName } from '../../../common/constants/queryParams'
import { airplaneModeSessionStorageKey } from '../../../common/constants/sessionStorage'
import { atom } from 'jotai'

export type DevToolsState = {
  showDetailedFastingLog: boolean
  airplaneMode: boolean
  mocks: { [key in string]: any }
  writeQueryResponseToDisk: boolean
  networkOffline: boolean
}

export const devToolsState = atom<DevToolsState>(
  (() => {
    const networkOffline = typeof window !== 'undefined' && window.navigator.onLine === false
    const airplaneModeQueryParam =
      typeof window !== 'undefined' ? new URLSearchParams(location.search).get(airplaneModeParamName) : null
    const airplaneModeSessionStorage =
      typeof window !== 'undefined' && sessionStorage.getItem(airplaneModeSessionStorageKey) === 'true'

    const airplaneMode =
      airplaneModeSessionStorage ||
      networkOffline ||
      (!isNil(airplaneModeQueryParam) && airplaneModeQueryParam !== 'false')

    return {
      showDetailedFastingLog: false,
      networkOffline,
      airplaneMode,
      writeQueryResponseToDisk: false,
      mocks: getConfig()?.publicRuntimeConfig.mocks ?? {},
    }
  })()
)
