import { referralSourceQueryParamName } from './queryParams'

export const metricsExplorerExpandedRowIdsSessionStorageKey = 'metricsExplorerExpandedRowIds'
export const referralSourceSessionStorageKey = referralSourceQueryParamName
export const loginInitiatedAsSignupSessionStorageKey = 'loginInitiatedAsSignup'
export const lastRecordedKpiDateSessionStorageKey = 'lastRecordedKpiDate'
export const currentPathSessionStorageKey = 'currentPath'
export const previousUrlAndScrollPositionSessionStorageKey = 'previousUrlAndScrollPosition'
export const isNewUserSessionStorageKey = 'isNewUser'
export const isNewUserFirstLoggedInPageLoadSessionStorageKey = 'isNewUserFirstLoggedInPageLoad'
export const labUserNewAccountModalCompleteSessionStorageKey = 'labUserNewAccountModalComplete'
export const atHomeLabsDrawRequirementShownForCartIdSessionStorageKey = 'atHomeLabsDrawRequirementShownForCartId'
export const currentCartShippingDetailsConfirmedSessionStorageKey = 'currentCartShippingDetailsConfirmed'
export const airplaneModeSessionStorageKey = 'airplaneMode'

export const persistedOnboardingSurveyDataSessionStorageKey = 'persistedOnboardingSurveyData'
export const activeOnboardingStepUrlSessionStorageKey = 'activeOnboardingStepUrl'
export const activeOnboardingSurveySlideIndexSessionStorageKey = 'activeOnboardingSurveySlideIndex'
export const surveySkippedGoalsDataSessionStorageKey = 'surveySkippedGoalsData'
export const stopTrackingSurveyStepUrlSessionStorageKey = 'stopTrackingSurveyStepUrl'
export const userRetakingSurveySessionStorageKey = 'userRetakingSurvey'
