import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'
import { NextImage } from '../../../common/functionality/NextImage'
import { shopSupplementsRoute } from '../../../common/constants/routes'
import { NavigationArrow } from '../../../assets/components/NavigationArrow'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { Display } from '../../../common/style/Display'
import { cdnUrl } from '../../../common/constants/s3ImageUrls'
import { NextLink } from '../../../common/functionality/NextLink'

const Container = styled(NextLink)`
  background: ${theme.colors.primary200};
  display: flex;
  padding: 24px 16px;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  overflow: hidden;
  transition: background-color 0.3s;

  ${tabletDownMq} {
    padding: 16px 0;
    gap: 8px;
  }
`

const ProductSquare = styled.div`
  display: flex;
  overflow: hidden;
  width: 177px;
  height: 177px;
  position: relative;
  padding: 19px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: ${theme.colors.primary200};
  box-shadow: 0px 15px 34px 0px rgba(1, 20, 98, 0.06);

  ${tabletDownMq} {
    border-radius: var(--Numbers-8, 8px);
    background: ${theme.colors.primary200};
    box-shadow: 0px 6.622px 15.01px 0px rgba(1, 20, 98, 0.06);
    display: flex;
    width: 69.5px;
    height: 69.5px;
    padding: 4.271px 3.742px 3.85px 4.38px;
    justify-content: center;
    align-items: center;
  }
`

const MemberBox = styled.div`
  border-radius: 16px;
  background: ${theme.colors.primary200};
  box-shadow: 0px 15px 34px 0px rgba(1, 20, 98, 0.06);
  padding: 24px;
  height: 177px;
  width: 302px;
  min-width: 302px;

  ${tabletDownMq} {
    border-radius: 8px;
    box-shadow: 0px 8.8px 19.947px 0px rgba(1, 20, 98, 0.06);
    height: 147px;
    padding: 12px;
    min-width: 225px;
  }
`

const MemberTitle = styled.div`
  color: ${theme.colors.primary900};
  font-size: 18px;
  font-weight: 600;
  height: 23px;
  line-height: 23px;

  ${tabletDownMq} {
    white-space: nowrap;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.3px;
  }
`

const MemberText = styled.div`
  color: ${theme.colors.primary900};
  font-size: 13px;
  font-weight: 400;
  margin-top: 8px;
  margin-bottom: 16px;
  height: 54px;
  line-height: 18px;

  ${tabletDownMq} {
    margin-top: 4px;
    margin-bottom: 12px;
  }
`

const ShopNowCta = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
  display: flex;
  width: min-content;
  width: 107px;
  padding: 6px 8px 6px 12px;
  border-radius: 20px;
  background: ${theme.colors.primary700};

  ${tabletDownMq} {
    padding: 4px 4px 4px 8px;
    border-radius: 8px;
  }

  :hover {
    background: ${theme.colors.primary800};
  }
`

const ShopNowText = styled.div`
  color: ${theme.colors.grayscalewhite};
  font-size: 14px;
  font-weight: 600;
  gap: 2px;
  white-space: nowrap;

  ${tabletDownMq} {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 15px; /* 125% */
    letter-spacing: 0.24px;
  }
`

const productUrls = ['motivation-101', 'brain-101', 'dake', 'magnesium-101', 'motivation-101', 'minerals-101'].map(
  (x) => cdnUrl(`upgrade-labs/home-page-paid-member-banner/supplement-${x}.webp`)
)

const ProductImageContentSpace = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

const sizes = { mobile: 61.39, desktop: 139 }

function Product({ imageSrc, size }: { imageSrc: string; size: number }) {
  const alt = imageSrc.split('/').pop()?.replace('supplement-', '').replace('.webp', '') ?? 'supplement'
  return (
    <ProductSquare>
      <ProductImageContentSpace>
        <NextImage
          src={imageSrc}
          width={size}
          height={size}
          style={{ objectFit: 'contain', mixBlendMode: 'multiply', width: size, height: size }}
          alt={alt}
        />
      </ProductImageContentSpace>
    </ProductSquare>
  )
}

function makeProductSectionDesktop(products: string[]) {
  return products.map((x, i) => <Product key={i} imageSrc={x} size={sizes.desktop} />)
}

const MobileColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

function makeProductSectionMobile(products: string[]) {
  const productSections = []
  for (let i = 0; i < products.length; i += 2) {
    const section = products.slice(i, i + 2)
    productSections.push(section)
  }

  return productSections.map((section, index) => (
    <MobileColumn key={index}>
      {section.map((x, i) => (
        <Product key={i} imageSrc={x} size={sizes.mobile} />
      ))}
    </MobileColumn>
  ))
}

export function ExistingMemberProductSubBanner() {
  const fourProducts = productUrls.slice(0, 4)
  const twoProducts = productUrls.slice(4)
  return (
    <div className="w-full overflow-hidden border">
      <Container href={shopSupplementsRoute}>
        <Display isTabletUp style={{ display: 'flex', gap: 24 }}>
          {makeProductSectionDesktop(fourProducts)}
        </Display>
        <Display isTabletDown style={{ display: 'flex', gap: 8 }}>
          {makeProductSectionMobile(twoProducts)}
        </Display>
        <MemberBox>
          <MemberTitle>Thanks for being a Member.</MemberTitle>
          <MemberText>
            Your membership gives you unbeatable pricing on supplements from top brands and curated lab test packages.
          </MemberText>
          <ShopNowCta>
            <ShopNowText>Shop Now</ShopNowText>
            <NavigationArrow orientation="right" color={theme.colors.grayscalewhite} size={18} />
          </ShopNowCta>
        </MemberBox>
        <Display isTabletUp style={{ display: 'flex', gap: 24 }}>
          {makeProductSectionDesktop(twoProducts)}
        </Display>
        <Display isTabletDown style={{ display: 'flex', gap: 8 }}>
          {makeProductSectionMobile(fourProducts)}
        </Display>
      </Container>
    </div>
  )
}
