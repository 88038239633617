import { createHttpLink } from '@apollo/client'
import {
  clientStellateCacheableGqlHttpEndpoint,
  clientRelayEndpoint,
} from '../../../common/constants/envDependentRoutes'
import { externalFranchiseEndpoint } from '../../../common/constants/routes'
import { clientVersionHeaders } from '../common'

export const httpLink = createHttpLink({
  uri: clientStellateCacheableGqlHttpEndpoint,
  credentials: 'include',
  headers: clientVersionHeaders,
})
export const relayLink = createHttpLink({
  uri: clientRelayEndpoint,
  credentials: 'include',
  headers: clientVersionHeaders,
})

export const franchiseLink = createHttpLink({
  uri: externalFranchiseEndpoint,
})
