import * as React from 'react'
import styled from '@emotion/styled'
import { theme } from '../../../../styles/theme'
import { NextLink } from '../../../../common/functionality/NextLink'
import { LazyLoadingSpinner } from '../../../../common/components/LazyLoadingSpinner'
import { HomePageCarouselProps } from './DesktopCarousel'
import { NextImage } from '../../../../common/functionality/NextImage'

const Container = styled.div`
  position: relative;
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-right: -16px;
  overflow: auto;
`

const Slider = styled.div`
  padding: 0 16px;
  display: flex;
  min-width: fit-content;
`
const SliderBubble = styled.div`
  border-radius: 12px;
  background: ${theme.colors.grayscalewhite};
  padding: 16px;
  display: flex;
  width: 100%;
`

const SlideSection = styled(NextLink)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 124px;

  :not(:first-of-type) {
    margin-left: 16px;
  }
`

const Name = styled.div`
  color: ${theme.colors.grayscale900};
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  flex: 1;
  display: flex;
  align-items: center;
`

const SlideSectionMiddle = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4px 0;
  flex: 1;
  justify-content: space-between;
`

const PriceContainer = styled.div`
  text-align: center;
`

const NonMemberPrice = styled.div`
  color: ${theme.colors.grayscale700};
  font-size: 13px;
  font-weight: 500;
`

const MemberPrice = styled.div`
  color: ${theme.colors.grayscale700};
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.28px;
`

export function MobileCarousel({ products, loading }: HomePageCarouselProps) {
  return (
    <Container>
      <Slider>
        <SliderBubble style={{ minHeight: products.length ? 'unset' : 225 }}>
          {loading && !products.length ? (
            <LazyLoadingSpinner />
          ) : (
            products.map((x) => (
              <SlideSection key={x.id} href={x.link}>
                <NextImage width={84} height={84} src={x.image.url} alt={x.image.alternativeText ?? x.name} />
                <SlideSectionMiddle>
                  <Name>{x.name}</Name>
                  <PriceContainer>
                    <NonMemberPrice>{x.prices.freeMember.display} Retail</NonMemberPrice>
                    <MemberPrice>{x.prices.paidMember.display} Member</MemberPrice>
                  </PriceContainer>
                </SlideSectionMiddle>
              </SlideSection>
            ))
          )}
        </SliderBubble>
      </Slider>
    </Container>
  )
}
