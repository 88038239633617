import { prodHostname } from './prodHostname.mjs'

export const imageDomains = [
  'app.axo.health',
  'lh3.googleusercontent.com',
  'cdn.shopify.com',
  'm.media-amazon.com',
  'magnesiumbreakthrough.com',
  'i5.walmartimages.com',
  'realize-prod-images.s3.us-west-2.amazonaws.com',
  'realize-dev-images.s3.us-west-2.amazonaws.com',
  'realize-icons.s3.us-west-2.amazonaws.com',
  'ui-avatars.com',
  'app.realize.me',
  's.gravatar.com',
  prodHostname,
]
