import * as React from 'react'
import { Portal, PortalProps } from '../../../common/functionality/Portal'
import { Overlay } from '../../../common/style/Overlay'
import { getCssProp, Styles } from '../../../utilities/getCssProp'
import { appModalId } from '../../navbar/templates/AppLayoutTemplate'
import { StandardModalContainer } from '../molecules/StandardModalContainer'
import { BooleanOrMediaQueries, useSetModal } from '../modalState'
import styled from '@emotion/styled'
import { modalCloseXClassName } from '../atoms/StandardModalCard'

type Props = React.PropsWithChildren<{
  standardContainer?: boolean
  isClosable?: boolean
  isVisible: boolean
  takeoverScreenExcludingAppHeader?: BooleanOrMediaQueries
  styles?: Styles
  onClose?: () => void
  preserveModalStateOnClose?: boolean
  id?: string
  containerStyles?: Styles
  portalDestination?: PortalProps['to']
}>

type WrapperProps = React.PropsWithChildren<
  Pick<Props, 'containerStyles' | 'isClosable' | 'onClose' | 'standardContainer'>
>

function Wrapper({ children, isClosable, standardContainer, containerStyles, onClose }: WrapperProps) {
  const css = getCssProp(containerStyles)

  return standardContainer ? (
    <StandardModalContainer isClosable={isClosable} cssProp={css} onClose={onClose}>
      {children}
    </StandardModalContainer>
  ) : (
    <>{children}</>
  )
}

const HideCloseX = styled.span<{ $hideCloseX: boolean }>`
  /* allow all clicks to pass through this component since it wraps the modal. add the pointer-events back to direct
descendants so this wrapper (which wraps the modal container's margin) doesn't block any click events through to the
children */

  > * {
    pointer-events: auto;
  }

  .${modalCloseXClassName} {
    display: ${(p) => (p.$hideCloseX ? 'none' : 'initial')} !important;
  }
`

/**
 * InlineModal is the base Modal wrapper. it wraps the Modal component that we use to set/sync modals in jotai state.
 * Use setModal directly instead of this component unless the dom heirarchy needs to be maintained for the child modal
 * component as this gives greater certainty that the modal will behave deterministically regardless of where it is used
 * and that it will sync up with other modals correctly such that only one is ever visible at a time
 */
export function InlineModal({
  children,
  isVisible,
  styles,
  onClose,
  id,
  standardContainer,
  isClosable = true,
  preserveModalStateOnClose,
  containerStyles,
  portalDestination,
  takeoverScreenExcludingAppHeader,
}: Props) {
  const setModal = useSetModal()

  return (
    <Portal to={portalDestination || `#${appModalId}`}>
      <Overlay
        isVisible={isVisible}
        takeoverScreenExcludingAppHeader={takeoverScreenExcludingAppHeader}
        onClick={() => {
          if (isClosable) {
            if (!preserveModalStateOnClose) {
              setModal()
            }
            onClose?.()
          }
        }}
        id={id}
        styles={styles}
        style={{ zIndex: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <HideCloseX $hideCloseX={isClosable === false}>
          <Wrapper
            isClosable={isClosable}
            standardContainer={standardContainer}
            containerStyles={containerStyles}
            onClose={onClose}
          >
            {children}
          </Wrapper>
        </HideCloseX>
      </Overlay>
    </Portal>
  )
}
