import styled from '@emotion/styled'
import { tabletDownMq } from '../../../styles/mediaQueries'
import { NextLink } from '../../../common/functionality/NextLink'
import { useNavLinks, NavLink as NavLinkType } from '../../side-navigation/hooks/useNavLinks'
import { typedFalsyFilter } from '../../../typescript/guards/typedFalsyFilter'
import { shopRoute } from '../../../common/constants/routes'
import { useRecommendationsLinkAccountingForLastVisitedScreen } from '../../recommendations/hooks/useRecommendationsLinkAccountingForLastVisitedScreen'
import { LeftSideItemContainer } from '../atoms/NavItemContainers'
import { usePathname } from 'next/navigation'

const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  ${tabletDownMq} {
    flex: 1;
  }
`

type Input = {
  navLinks: NavLinkType[]
  pathname: string
  dynamicRecommendationsRoute: string
}

const makeLinks = ({ navLinks, pathname, dynamicRecommendationsRoute }: Input) =>
  [
    {
      href: shopRoute,
    },
    {
      href: dynamicRecommendationsRoute,
    },
  ]
    .map((header) => {
      // TODO REDO THIS!! SEEMS VERY DUPLICATIVE
      const matchingNavLink = navLinks?.find((x) => {
        const urlMatches =
          ('url' in x && x.url && (x.url === header.href || x.url.split('?')[0] === header.href)) ||
          x.additionalActiveRouteMatches?.includes(pathname)

        const subNavUrlMatches =
          'subnav' in x &&
          (x.subnav.some((subLink) => subLink.url === header.href || subLink.url.split('?')[0] === header.href) ||
            x.subnav.some((sublink) => sublink.additionalActiveRouteMatches?.includes(pathname)))

        return urlMatches || subNavUrlMatches
      })

      const subNavLinks =
        matchingNavLink && 'subnav' in matchingNavLink
          ? matchingNavLink.subnav.flatMap((x) => [x.url].concat(x.additionalActiveRouteMatches ?? []))
          : []
      const activeUrls = [
        ...new Set([header.href].concat(matchingNavLink?.additionalActiveRouteMatches ?? []).concat(subNavLinks)),
      ]
      const isActive = activeUrls.some((x) => x === pathname || x.split('?')[0] === pathname)
      if (!matchingNavLink) return null
      return { ...header, title: matchingNavLink?.title, isActive }
    })
    .filter(typedFalsyFilter)

export function QuickLinks() {
  const { navLinks } = useNavLinks()
  const pathname = usePathname() ?? ''

  const dynamicRecommendationsRoute = useRecommendationsLinkAccountingForLastVisitedScreen()

  const links = makeLinks({
    navLinks,
    pathname,
    dynamicRecommendationsRoute,
  })

  return (
    <Container>
      {links.map((x) => (
        <LeftSideItemContainer
          key={x.title}
          $text={x.title}
          $largestWeight={700}
          $isActive={x.isActive}
          style={{ borderLeft: 'none' }}
        >
          <NextLink href={x.href}>{x.title}</NextLink>
        </LeftSideItemContainer>
      ))}
    </Container>
  )
}
