import * as React from 'react'
import { ApolloNextAppProvider } from '@apollo/experimental-nextjs-app-support'
import { getMakeApollo, MakeApolloOptions } from './getMakeApollo'

type ContextValue = { reinitApolloClient: (options?: MakeApolloOptions) => void } | undefined

const Context = React.createContext<ContextValue>(undefined)

export function ApolloProvider({ children }: React.PropsWithChildren) {
  const [currentMakeapollo, setCurrentMakeApollo] = React.useState(getMakeApollo)

  const reinitApolloClient = React.useCallback((clientOptions?: MakeApolloOptions) => {
    return setCurrentMakeApollo(getMakeApollo(clientOptions))
  }, [])

  return (
    <Context.Provider value={{ reinitApolloClient }}>
      <ApolloNextAppProvider makeClient={currentMakeapollo}>{children}</ApolloNextAppProvider>
    </Context.Provider>
  )
}

export const useReinitApolloClient = () => {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error("'useReinitApolloClient' must be used within a ReinitializableApolloProvider")
  }
  return context
}
