import React from 'react'
import styled from '@emotion/styled'
import { getCssProp, Styles } from '../../utilities/getCssProp'
import { ClickEvent } from '../../typescript/utility'
import { bodyScroll } from '../../utilities/bodyScroll'
import { transientOptions } from './transientOptions'
import { mqKeyToValueDict } from './Display'
import isBoolean from 'lodash/isBoolean'
import { BooleanOrMediaQueries } from '../../features/modal/modalState'
import { theme } from '../../styles/theme'
import { hasKey } from '../../typescript/guards/hasKey'

type ContainerProps = {
  $isVisible: boolean
  $takeoverScreenExcludingAppHeader?: BooleanOrMediaQueries
}
const Container = styled('div', transientOptions)<ContainerProps>`
  ${(p) => (p.$isVisible ? '' : 'pointer-events: none;')}
  position: fixed;
  display: flex;
  top: ${(p) =>
    isBoolean(p.$takeoverScreenExcludingAppHeader) && p.$takeoverScreenExcludingAppHeader
      ? theme.elements.appHeaderHeight
      : 0};
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  opacity: ${(p) => (p.$isVisible ? '1' : '0')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
  transition: opacity ${(p) => (p.$isVisible ? 0.5 : 0.35)}s ease-out;
  background-color: rgba(30, 30, 30, 0.5);
  backdrop-filter: blur(2px);

  ${(p) =>
    Object.entries(p.$takeoverScreenExcludingAppHeader ?? {}).map(([mqKey, value]) => {
      if (hasKey(mqKeyToValueDict, mqKey)) {
        return `${mqKeyToValueDict[mqKey]} {top: ${value ? theme.elements.appHeaderHeight : 0}`
      }
    })}
`

type Props = {
  isVisible: boolean
  takeoverScreenExcludingAppHeader?: BooleanOrMediaQueries
  children?: React.ReactNode
  onClick?: (event?: ClickEvent<HTMLDivElement>) => void
  styles?: Styles
  style?: React.CSSProperties
  id?: string
}

// TODO may want to add a provider responsible for the overflow style etc, so when we have multiple Overlays, their
// interaction doesn't interfere with one another, and the style is just one way if any are present, and another way if
// none are present. Could see this implementation causing race conditions as their visibilities are toggled

export const overlayVisibleClassName = 'overlay-visible'
const overlayInvisibleClassName = 'overlay-invisible'
export const Overlay = ({
  isVisible = false,
  children,
  onClick = () => {},
  styles,
  style,
  id: idProp,
  takeoverScreenExcludingAppHeader,
}: Props) => {
  React.useEffect(() => {
    // any time any overlay's visibility changes, check if there is any existing overlay that is visible. if so, we
    // always want the body to lock. if visibility is changing in any overlay and no overlays are mounted and visible, unlock the body
    const someOverlayIsVisible = [...document.getElementsByClassName(overlayVisibleClassName)].length > 0

    if (someOverlayIsVisible) {
      bodyScroll.lock()
    } else {
      bodyScroll.unlock()
    }
    return () => {
      const overlaysVisibleAfterUnmount = [...document.getElementsByClassName(overlayVisibleClassName)].length > 0
      if (!overlaysVisibleAfterUnmount) bodyScroll.unlock()
    }
  }, [isVisible])

  const fallbackId = React.useId()
  const id = idProp || fallbackId

  const newCss = getCssProp(styles)
  // override style with any custom incoming css styles. if we just feed both in, the style object will win out when
  // consumed by the component if there is a conflict, and we want to be able to override anything on a per component  basis
  const css = { ...style, ...newCss }

  return (
    // eslint-disable-next-line styled-components-a11y/no-static-element-interactions, styled-components-a11y/no-static-element-interactions
    <Container
      // when wrapping child components, we don't want events to bubble through to the overlay as though it was clicked when
      // the foreground item was actually the target of the event
      onMouseDown={(e: ClickEvent<HTMLDivElement>) => {
        if (!e || (e?.target as HTMLDivElement | undefined)?.id === id) {
          onClick(e)
        }
      }}
      className={isVisible ? overlayVisibleClassName : overlayInvisibleClassName}
      id={id}
      $isVisible={isVisible}
      $takeoverScreenExcludingAppHeader={takeoverScreenExcludingAppHeader}
      css={css}
    >
      {/* for a11y, can't nest focusable elements, so we want to 1) not use the a11y friendly `accessibleNoninteractiveElementClick` function as the onclick for the overlay (adds focusability) to avoid the nesting, and 2) add a hidden button element which accomplishes the click functionality for screen readers */}
      {/*
    // @ts-ignore */}
      <button hidden onClick={(e) => onClick(e)} aria-label="close-overlay-content"></button>
      {children}
    </Container>
  )
}
