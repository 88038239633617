export const franchiseFontCssVarNameDefinitions = {
  manrope: '--manrope',
  oswald: '--oswald',
}

type FontCssVarNameDefinitions = typeof franchiseFontCssVarNameDefinitions

const fonts = Object.entries(franchiseFontCssVarNameDefinitions).reduce<FontCssVarNameDefinitions>(
  (acc, [k, v]) => ({ ...acc, [k]: `var(${v})` }),
  {} as FontCssVarNameDefinitions
)

export const franchisePageTheme = {
  colors: {
    praiseOfShadow: '#221551',
    dramatist: '#4F4574',
    indiaInk: '#4F4474',
    slateGrey: '#5B656D',
    indocileTiger: '#BB6F00',
    orangePepper: '#D98100',
    fleurDeSelCaramel: '#DC8403',
    hotterButter: '#EA8D04',
    yamabukiGold: '#FFA400',
    raichuOrange: '#F2A83B',
    whippedApricot: '#FFD399',
    augustMorning: '#FFD79C',
    tunisianStone: '#FFDFB5',
    blankCanvas: '#FFEFD7',
    crushedCashew: '#FFEDD5',
    jupiter: '#E2E2E2',
    oldWine: '#C03744',
    shinshu: '#8C1823',
    aloha: '#15B097',
  },
  fonts,
}
