import * as React from 'react'
import { useSetModal } from '../../modal/modalState'
import dynamic from 'next/dynamic'
import { StringParam, useQueryParam } from 'use-query-params'
import { auth0QueryParamNames } from '../../../common/constants/queryParams'

/* LANDING URL
https://app.upgradehealth.com/?supportSignUp=true&supportForgotPassword=true&message=Your%20email%20was%20verified.%20You%20can%20continue%20using%20the%20application.&success=true&code=success
*/

const LazyEmailVerificationModal = dynamic(() =>
  import('../atoms/EagerEmailVerificationModal').then((x) => x.EagerEmailVerificationModal)
)

export function useShowEmailVerificationModal() {
  const [messageQueryParam] = useQueryParam(auth0QueryParamNames.message, StringParam)

  const setModal = useSetModal()

  React.useEffect(() => {
    const isEmailVerification = messageQueryParam?.includes('verified') && messageQueryParam?.includes('email')
    if (isEmailVerification) {
      setModal(<LazyEmailVerificationModal />)
    }
    return () => {}
  }, [messageQueryParam, setModal])

  return <>{null}</>
}
