import { useUnmountModalIfRouteChangedSinceMount } from '../hooks/useRouteChangedSinceMount'
import { useModalState } from '../modalState'
import { InlineModal } from './InlineModal'
import dynamic from 'next/dynamic'

const LazyFullscreenGradientTakeover = dynamic(() =>
  import('../atoms/FullscreenGradientTakeover').then((x) => x.FullscreenGradientTakeover)
)

function LazyTakeoverWrapper({ isVisible, children }: React.PropsWithChildren<{ isVisible?: boolean }>) {
  // anytime it has been visible, load it and feed in the isVisible prop so the transitions occur
  return isVisible ? <LazyFullscreenGradientTakeover>{children}</LazyFullscreenGradientTakeover> : <>{children}</>
}

export const overlayId = 'overlay'

// TODO get rid of or significantly rewrite the onClose and standard container props. onclose only fires when the x is
// clicked if the `standardContainer` arg is true, which is not what you would expect (looks like target  modal uses it
// incorrectly assuming this will happen). standard container is also rarely used and not really any easier than just
// using the component itself. also needs to be standardized ironically because so many of our modals have different
// widths etc. than the generic one.
export function Modal() {
  const [
    {
      component: ActiveModal,
      takeoverScreenExcludingAppHeader,
      onClose,
      isClosable,
      standardContainer,
      preserveModalStateOnClose,
      overlayStyles,
      isFullscreenGradientTakeover,
      preventUnmountOnPathnameChange,
    },
  ] = useModalState()

  useUnmountModalIfRouteChangedSinceMount({ skip: !!preventUnmountOnPathnameChange })

  return (
    <InlineModal
      isVisible={!!ActiveModal}
      takeoverScreenExcludingAppHeader={takeoverScreenExcludingAppHeader}
      id={overlayId}
      onClose={onClose}
      isClosable={isClosable}
      standardContainer={standardContainer}
      preserveModalStateOnClose={preserveModalStateOnClose}
      styles={overlayStyles}
    >
      <LazyTakeoverWrapper isVisible={isFullscreenGradientTakeover}>
        {!!ActiveModal && <ActiveModal />}
      </LazyTakeoverWrapper>
    </InlineModal>
  )
}
