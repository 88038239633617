import { homeRoute } from '../../../common/constants/routes'
import { NextLink } from '../../../common/functionality/NextLink'
import styled from '@emotion/styled'
import { transientOptions } from '../../../common/style/transientOptions'
import AxoLogoWhiteTextTransparentBackgroundSvg from '../../../assets/branding/axo-logo-white-text-transparent-background.svg'
import { Display } from '../../../common/style/Display'
import { AxoByUpgradeLabsLogo } from '../../../assets/branding/axo-by-upgrade-labs-logo-white-text-transparent-background'

const Container = styled.div`
  padding: 0 var(--Numbers-8, 8px);
  display: flex;
  align-items: center;
  justify-content: center;
`

const Link = styled(NextLink, transientOptions)`
  align-self: stretch;
  display: flex;
  align-items: center;
`

export function HomeButton() {
  const href = homeRoute

  return (
    <Link href={href}>
      <Container>
        <Display isTabletDown>
          <AxoLogoWhiteTextTransparentBackgroundSvg />
        </Display>
        <Display isTabletUp>
          <AxoByUpgradeLabsLogo />
        </Display>
      </Container>
    </Link>
  )
}
