import * as React from 'react'
import { usePathname } from 'next/navigation'
import CartSvg from '../../../../assets/icons/cart.svg'
import CartWithDotSvg from '../../../../assets/icons/cart-with-dot.svg'
import { shopCartRoute, shopLabTestsRoute } from '../../../../common/constants/routes'
import { NextLink } from '../../../../common/functionality/NextLink'
import styled from '@emotion/styled'
import { hamburgerContainerStyles } from '../../../side-navigation/atoms/Hamburger'
import { transientOptions } from '../../../../common/style/transientOptions'
import { useShoppingCart } from '../../../shop/hooks/useShoppingCart/useShoppingCart'
import dynamic from 'next/dynamic'
import { useIsMounted } from '../../../../hooks/useIsMounted'

const LazyCartDropdown = dynamic(() => import('./EagerCartDropdown').then((x) => x.EagerCartDropdown))

const Container = styled('div', transientOptions)<{ $isActive: boolean }>`
  position: relative;

  ${hamburgerContainerStyles}
`

const Link = styled(NextLink, transientOptions)<{ $isActive: boolean }>`
  position: absolute;
  z-index: 1;
`

export function CartNavItem() {
  const pathname = usePathname()

  const { itemCount } = useShoppingCart().cart

  const [isOpen, setIsOpen] = React.useState(false)
  const [isHovering, setIsHovering] = React.useState(false)
  React.useEffect(() => {
    setIsOpen(isHovering)
  }, [isHovering])

  const isActive = pathname === shopCartRoute

  React.useEffect(() => {
    setIsOpen(false)
  }, [pathname])

  const isMounted = useIsMounted()

  const isLabTests = pathname === shopLabTestsRoute

  return (
    <Container
      $isActive={isActive}
      onMouseEnter={() => !isActive && !isLabTests && setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <Link href={shopCartRoute} $isActive={isActive} onClick={() => setIsOpen(false)}>
        {itemCount ? <CartWithDotSvg /> : <CartSvg />}
      </Link>

      {isMounted && <LazyCartDropdown isOpen={isOpen} setIsOpen={setIsOpen} isHovering={isHovering} />}
    </Container>
  )
}
