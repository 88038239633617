import { atom, useAtom } from 'jotai'
import * as React from 'react'

// TODO at some point this should probably be refactored/renamed to navbar instead of announcement banner, and have
// announcement banner state fall under the umbrella of the navbar since we are now adding nav related state and actions
// to this atom
type JsxFunction = (p?: any) => JSX.Element
type Options = Partial<{
  hideHeaderOnScroll: boolean
}>
type AnnouncementBannerState = { component: JsxFunction | null } & Options

const initialState: AnnouncementBannerState = {
  component: null,
  hideHeaderOnScroll: true,
}

const announcementBannerState = atom(initialState)

type SetAnnouncementBannerInput = JsxFunction | JSX.Element | undefined

export function useSetAnnouncementBanner() {
  const [state, setState] = useAtom(announcementBannerState)

  const setAnnouncementBanner = React.useCallback(
    (input?: SetAnnouncementBannerInput | undefined, options?: Options) => {
      const component = typeof input == 'function' ? input : typeof input === 'object' ? () => input : null
      return setState((s) => ({
        ...s,
        ...options,
        component,
      }))
    },
    [setState]
  )

  return {
    setAnnouncementBanner,
    AnnouncementBanner: state.component,
    jotai: [state, setState] as const,
  }
}

export const useAnnouncementBannerState = () => {
  const [state, setState] = useAtom(announcementBannerState)
  return { ...state, setState }
}
