'use client'
import * as React from 'react'
import { useShoppingCart } from '../../../hooks/useShoppingCart/useShoppingCart'
import { shopCartRoute } from '../../../../../common/constants/routes'
import { enum_stripe_product_type_enum } from '../../../../../generated/graphql'
import { upgradePackageOrder } from './useComparePackages'
import { useSetModal } from '../../../../modal/modalState'
import { makeDataAttributes } from '../../../../analytics/gtmDataLayerVariableDataAttributes'
import { LazyReplacePackageConfirmation } from '../atoms/ReplacePackageConfirmation/LazyReplacePackageConfirmation'
import { useRouter } from 'next/navigation'

type Input = { productId: string; slug: string; name: string } | null

export function useAddPackageCtaProps(input: Input) {
  const { productId, slug, name } = input ?? {}
  const { cart, addProduct } = useShoppingCart()

  const { push } = useRouter()

  const inCart = productId && cart.items[productId]

  const setModal = useSetModal()

  return React.useMemo(
    () => ({
      gtmDataLayerVariableDataAttributes: !inCart
        ? makeDataAttributes({ action: 'add to cart', category: 'package', label: productId ?? 'PRODUCT ID MISSING' })
        : {},
      inCart,
      onClick: () => {
        if (!slug || !productId) return
        const currentCartPackage = Object.values(cart.items).find(
          (item) => item.product_type === enum_stripe_product_type_enum.PACKAGE
        )

        const add = async () => {
          if (!inCart) {
            await addProduct(productId, name ?? null)
          }
          push(shopCartRoute)
          return
        }

        if (currentCartPackage) {
          const isCustomPackage = currentCartPackage.isCustomPackage
          const currentPackageRank = upgradePackageOrder.findIndex((p) => p === currentCartPackage.name)
          const nextPackageRank = upgradePackageOrder.findIndex((p) => p === name)

          if (currentPackageRank > nextPackageRank || isCustomPackage) {
            return setModal(<LazyReplacePackageConfirmation add={add} />)
          }
        }
        add()
      },
      children: inCart ? 'Go to Cart' : 'Buy Now',
    }),
    [addProduct, productId, name, inCart, push, slug, cart.items, setModal]
  )
}
