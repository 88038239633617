'use client'
import * as React from 'react'
import { v4 } from 'uuid'
import { anonymousUserUnclaimedCartIdLocalStorageKey } from '../../../../common/constants/localStorage'
import {
  CartFieldsFragment,
  GetCartQuery,
  StripeProductPriceFieldsFragment,
  UpdateCartMutationOptions,
  enum_membership_tier_enum,
  enum_stripe_product_type_enum,
  useClaimCartMutation,
  useEmptyCartMutation,
  useGetCartLazyQuery,
  useRealizeTosDataLazyQuery,
  useSetPromoMutation,
  useUpdateCartMutation,
} from '../../../../generated/graphql'
import { useAutoUpdatingRef } from '../../../../hooks/useAutoUpdatingRef'
import { typedFalsyFilter } from '../../../../typescript/guards/typedFalsyFilter'
import { featureFlags } from '../../../auth/FeatureFlagProvider'
import { useAccount } from '../../../auth/hooks/useAccount'
import { useFeatureFlag } from '../../../auth/hooks/useFeatureFlag'
import { productTypeIsLabProduct } from '../../utils/productTypeIsLabProduct'
import {
  RequirementsState,
  defaultRequirementsStateFactory,
  purchaseRequirementsToRequirementsState,
  requirementsSteps,
} from './requirementsState'
import { termsOfUseRoute } from '../../../../common/constants/routes'
import { makeArray } from '../../../../utilities/makeArray'
import { sendFacebookConversionEventFromClient } from '../../../analytics/sendFacebookConversionEventFromClient'
import { useSharedFacebookConversionDataRef } from '../../../analytics/hooks/useSharedFacebookConversionDataRef'
import { useToast } from '../../../toast/useToast'
import { ProcessedPrice, getPrices, getProcessedPrice, type Prices } from '../../features/labs/hooks/useLabProducts'
import { getShopPagesPriceDisplay } from '../../utils/getShopPagesPriceDisplay'
import isNil from 'lodash/isNil'
import { analytics } from '../../../analytics/analytics'
import { Falsy } from 'utility-types'
import { isRealNumber } from '../../../../typescript/guards/isRealNumber'
import { atom, useAtom } from 'jotai'

export const realizeTosName = 'AXO Terms of Use'

export type CartProduct = {
  name: string
  product_type: enum_stripe_product_type_enum
  quantity: number
  quantityMin: number
  quantityMax: number
  product_id: string
  total: ProcessedPrice
  subtotal: ProcessedPrice
  requiredBy: string[]
  slug: string
  requires: string[]
  price: number | null
  // !!TODO make sure we still need this anywhere
  additionalPriceData: (Prices & { total: ProcessedPrice }) | null
  image: { url: string; alternativeText?: string } | null
  isCustomPackage: boolean
  componentProducts: (Pick<CartProduct, 'name' | 'product_type' | 'product_id' | 'slug'> & {
    displayPrice: ProcessedPrice
    unrealizedMembershipDiscountPotential: number
    paidMemberPrice: ProcessedPrice
    freeMemberPrice: ProcessedPrice
  })[]
}

export type OptionalDiscount = {
  numericCents: number
  display: string | null
} | null

export type ShoppingCartState = {
  cart: {
    id: null | string
    appliedMembershipTier: enum_membership_tier_enum | null
    total: number
    subtotal: number
    labFee: number
    tax: number
    itemCount: number
    items: Record<string, CartProduct>
    containsLabs: boolean
    containsMedications: boolean
    isSimpleProductOrder: boolean
    shippingCost: number
    membershipDiscount: ProcessedPrice | null
    promotionDiscount: ProcessedPrice | null
    isDiscountedView: boolean
    unrealizedMembershipDiscountPotential: number
    promoCode: string | null
  }
  inFlightChangesProductIds: string[]
  initialSyncComplete: boolean
  inProgressCartRequestIds: Set<string>
} & RequirementsState

const defaultState: ShoppingCartState = {
  cart: {
    id: null,
    appliedMembershipTier: null,
    containsMedications: false,
    isSimpleProductOrder: false,
    total: 0,
    subtotal: 0,
    labFee: 0,
    tax: 0,
    itemCount: 0,
    items: {},
    containsLabs: false,
    shippingCost: 0,
    membershipDiscount: null,
    promotionDiscount: null,
    isDiscountedView: false,
    unrealizedMembershipDiscountPotential: 0,
    promoCode: null,
  },
  inFlightChangesProductIds: [],
  initialSyncComplete: false,
  inProgressCartRequestIds: new Set(),
  ...defaultRequirementsStateFactory(),
}

export const shoppingCartAtom = atom(defaultState)

const getAnonCartId = () =>
  typeof window !== 'undefined' ? localStorage.getItem(anonymousUserUnclaimedCartIdLocalStorageKey) : null

// TODO this is clearly going against the grain to get a non-context based hook working that still has synchronized
// loading states/doesn't unnecessarily run initial fetches. to accomplish this without havin a provider that wraps the
// entire app, i am using jotai state, adding an id for every request and removing it in the promise finally, and if
// the set is empty, we know no loading is occuring. to track the initial fetch, i use a flag outside the hook as this
// will be tru throughout the lifespan of the app regardless of how many hooks are running, skip the initial query run,
// and call the refetch on first effect run if the flag is false. if we rely on the actual
// loading states for the mutations, they are only accurate if the the same hook which is paying attention to the
// loading is the one which called that specific version of the apollo query function as all of these functions are
// unique, and the loading state is not shared between them. should figure out a way to consolidate state while avoiding
// wrapping the whole app if possible

// !!UPDATE^^: if we want to use apollo loading state and have it avoid being off by one tick, we do indeed need to wrap
// all of their requests and track the loading ourselves prior to exection and on finally. otherwise, even if we
// consolidate jotai such that the actual request functions live in state, and only set them once when a component
// mounts (which we would place on any page we need the cart) and only set those functions if they are unset, we would
// still need to track the loading independently so as to avoid only setting loading to true after an effect ran, which
// is one tick later than desirable. hypothetically, we could move all of this logic into a component which we put
// anywhere we need the cart, and only have it run/set the request funcs which contain this loading tracking logic
// inside the first component of its type that mounts. however, that is complicated, and all this is doing is creating
// additional functions, none of which should create any real overhead, so gonna leave as is for now. perhaps circle
// back if we can get next 13 working such that we could have cross-page provider wrappers which would just run once
// across multiple pages. still probably worse than this though given this has no need to know where useCart will be
// used, it just immediately works any and everywhere with the only downside being function duplication, which is
// pretty irrelevant.

type SetQuantityData = { productId: string; quantity: number; name: string; isCustomPackageTest?: boolean }

type SetQuantitySetterFunc = (cart: ShoppingCartState['cart']) => SetQuantityData

export type UseShoppingCartReturn = ReturnType<typeof useShoppingCart>

type Product = NonNullable<NonNullable<CartFieldsFragment['lineItems']>[number]['product']>

function processAdditionalPriceData(
  product: Pick<Product, keyof StripeProductPriceFieldsFragment> | Falsy,
  total?: number
) {
  const totalOrCurrent = isRealNumber(total) ? total : product ? product.price_current?.price_usd : null

  return product && isRealNumber(totalOrCurrent)
    ? {
        ...getPrices(product),
        total: { numericCents: totalOrCurrent, display: getShopPagesPriceDisplay(totalOrCurrent) },
      }
    : null
}

export function useShoppingCart() {
  const { setErrorToast, setSuccessToast } = useToast()
  const { isAuthenticated, isLoading } = useAccount()

  const isAnonymousUser = !isAuthenticated && !isLoading

  const authCheckComplete = !isLoading

  const [shoppingCartState, setShoppingCartState] = useAtom(shoppingCartAtom)

  React.useEffect(() => {
    if (!isAuthenticated) setShoppingCartState((s) => ({ ...s, initialSyncComplete: false }))
  }, [isAuthenticated, setShoppingCartState])

  const { initialSyncComplete } = shoppingCartState

  // need to feed in any feature flag just to get a response for whether the flags are ready
  const { featureFlagsReady } = useFeatureFlag(featureFlags.atHomeLabDrawIntake)
  const atHomeLabDrawIntakeIsEnabled = useFeatureFlag(featureFlags.atHomeLabDrawIntake).isEnabled
  const consentRequirementIntakeIsEnabled = useFeatureFlag(featureFlags.consentRequirementIntake).isEnabled
  const isUseVitalContentEnabled = useFeatureFlag(featureFlags.useVitalContent).isEnabled

  const processShoppingCartState = (
    newCartFields: CartFieldsFragment | null,
    tosData?: Pick<GetCartQuery, 'has_accepted_latest_terms' | 'terms_of_service'>
  ) =>
    setShoppingCartState((prevShoppingCartState) => {
      const { appliedMembershipTier = null } = newCartFields ?? {}
      const items = (newCartFields?.lineItems ?? []).reduce<ShoppingCartState['cart']['items']>((acc, x) => {
        const { product, ...rest } = x
        const { product_type, name = '' } = product ?? {}

        if (!product_type || !name) return acc
        // ATM, we only want to show package requirements in the UI, so drop all the actions as well as none package
        // product requirements, like the membership fee
        const requires = (x.requires ?? [])
          .map((r) => {
            const isPackage = r.requiresLineItem.product?.product_type === enum_stripe_product_type_enum.PACKAGE
            return isPackage ? `${r.requiresLineItem.product?.name} Lab Package` : ''
          })
          .filter(typedFalsyFilter)

        const requiredBy = (x.requiredBy ?? []).map((rb) => rb.requiredByLineItem.product?.name ?? '')
        // const requirementActions = new Set(
        //   x.product.purchase_requirements
        //     .map((pr) => {
        //       switch (pr.__typename) {
        //         case 'ConsentPurchaseRequirementModel': {
        //           return pr.status === purchase_requirement_status.met ? null : pr.type
        //         }
        //         case 'CustomPurchaseRequirementModel': {
        //           return pr.status === purchase_requirement_status.met ? null : pr.type
        //         }
        //         case 'ProductPurchaseRequirementModel': {
        //           return null
        //         }
        //         default: {
        //           return null
        //         }
        //       }
        //     })
        //     .map((pr) => {
        //       const displayText =
        //         pr && pr in purchaseRequirementDisplayTextDict && keyIsIn(pr, purchaseRequirementDisplayTextDict)
        //           ? purchaseRequirementDisplayTextDict[pr]
        //           : null
        //       return displayText
        //     })
        //     .filter(typedFalsyFilter)
        // )

        const { images } = x.product?.content?.data?.attributes ?? {}
        const { url, alternativeText } = images?.data?.[0]?.attributes ?? {}

        const additionalPriceData = processAdditionalPriceData(x.product, x.total)

        return {
          ...acc,
          [x.productId]: {
            ...rest,
            product_id: x.productId,
            name,
            slug: x.product?.slug ?? '',
            product_type,
            requiredBy,
            requires,
            total: getProcessedPrice(x.total),
            subtotal: getProcessedPrice(x.subtotal),
            price: additionalPriceData?.current.numericCents ?? null,
            additionalPriceData,
            isCustomPackage: !!x.product?.is_custom,
            image: url ? { url, alternativeText } : null,
            quantityMin: x.quantityMin ?? 0,
            quantityMax: x.quantityMax ?? Infinity,
            componentProducts: (x.product?.package_products ?? []).map((p) => {
              const freeMemberPrice = getPrices(p.product).freeMember
              const paidMemberPrice = getPrices(p.product).paidMember
              const displayPrice =
                appliedMembershipTier !== enum_membership_tier_enum.FREE_MEMBER ? paidMemberPrice : freeMemberPrice

              const calculatedUnrealizedMembershipDiscountPotential =
                (displayPrice.numericCents ?? 0) - (paidMemberPrice.numericCents ?? 0)
              return {
                product_id: p.product.id,
                name: p.product.name,
                product_type: p.product.product_type,
                unrealizedMembershipDiscountPotential:
                  calculatedUnrealizedMembershipDiscountPotential > 0
                    ? calculatedUnrealizedMembershipDiscountPotential * x.quantity
                    : 0,
                displayPrice,
                paidMemberPrice: getProcessedPrice(p.product.price_paid_member?.price_usd),
                freeMemberPrice: getProcessedPrice(p.product.price_free_member?.price_usd),
                slug: p.product.slug ?? '',
              }
            }),
          },
        }
      }, {})

      const itemsArray = Object.values(items)

      const itemCount = itemsArray
        .filter(
          (x) =>
            x.product_type !== enum_stripe_product_type_enum.FEE &&
            x.product_type !== enum_stripe_product_type_enum.SHIPPING_FEE
        )
        .reduce<number>((acc, x) => acc + x.quantity, 0)

      const { total = 0 } = newCartFields ?? {}

      const unrealizedMembershipDiscountPotential = itemsArray.reduce((acc, x) => {
        const isCustomPackage = x.componentProducts.length > 0
        if (isCustomPackage) {
          const customPackageUnrealizedMembershipDiscountPotential = x.componentProducts.reduce(
            (acc2, cp) => acc2 + cp.unrealizedMembershipDiscountPotential,
            0
          )
          return acc + customPackageUnrealizedMembershipDiscountPotential
        } else {
          const current = x.additionalPriceData?.current.numericCents
          const paid = x.additionalPriceData?.paidMember.numericCents
          if (isNil(current) || isNil(paid) || paid >= current) return acc
          return acc + (current - paid) * x.quantity
        }
      }, 0)

      const labFee = itemsArray.reduce(
        (acc, x) => acc + (x.product_type === enum_stripe_product_type_enum.FEE && x.price ? x.price : 0),
        0
      )
      const shippingCost = itemsArray.reduce(
        (acc, x) => acc + (x.product_type === enum_stripe_product_type_enum.SHIPPING_FEE && x.price ? x.price : 0),
        0
      )

      const containsLabs = itemsArray.some((item) => productTypeIsLabProduct(item.product_type))

      const containsMedications = itemsArray.some(
        (item) => item.product_type === enum_stripe_product_type_enum.PRESCRIPTION
      )

      const { member_discount = 0, promotion_discount = 0, tax = 0 } = newCartFields?.cart ?? {}

      const membershipDiscount = getProcessedPrice(member_discount)
      const promotionDiscount = getProcessedPrice(promotion_discount)

      const totalDiscount = (membershipDiscount?.numericCents ?? 0) + (promotionDiscount.numericCents ?? 0)

      const subtotal = total - labFee - tax - shippingCost + totalDiscount

      const unclaimedAnonymousCartId = (isAnonymousUser && newCartFields?.id) || null

      const cart = {
        ...prevShoppingCartState.cart,
        appliedMembershipTier,
        total,
        subtotal,
        labFee,
        tax,
        itemCount,
        items,
        containsLabs,
        containsMedications,
        id: newCartFields?.id ?? null,
        shippingCost,
        isSimpleProductOrder: !containsLabs && !containsMedications,
        membershipDiscount,
        promotionDiscount,
        isDiscountedView: totalDiscount > 0,
        unrealizedMembershipDiscountPotential,
        promoCode: newCartFields?.applied_promo_code ?? null,
      }

      const existingTosData =
        prevShoppingCartState.requirements.steps[requirementsSteps.personalInfo].serviceLevelConsentPolicies.find(
          (x) => x.name === realizeTosName
        ) ??
        prevShoppingCartState.requirements.steps[requirementsSteps.contactInfo].serviceLevelConsentPolicies.find(
          (x) => x.name === realizeTosName
        )

      // if either the user has accepted the terms, or there is no existing state data saying they need to/none coming
      // in saying they need to, set realizeTos as null so it is not added to requirements. otherwise, if incoming
      // data says they need it, or there is no incoming data and the state data says they need it, feed it in so it
      // gets added to the requirements

      const tosId = tosData?.terms_of_service?.id ?? existingTosData?.id
      const realizeTos =
        tosData?.has_accepted_latest_terms || !tosId
          ? null
          : { id: tosId, name: realizeTosName, version: { id: tosId, external_url: termsOfUseRoute } }

      const requirements = purchaseRequirementsToRequirementsState({
        purchaseRequirements: newCartFields?.purchase_requirements,
        containsLabs,
        cartId: cart.id,
        realizeTos,
        flags: {
          atHomeLabDrawIntakeIsEnabled,
          consentRequirementIntakeIsEnabled,
          isUseVitalContentEnabled,
        },
      })

      // save the unclaimed cart id in session storage any time it comes back if we are anonymous so we can retrieve it
      // in the future. every time a call completes, we delete it. this way, we only save when anonymous, always get it from
      // the actual response, and as soon as we have retrieved it, we remove that state and only resave if we are still anonymous

      if (unclaimedAnonymousCartId) {
        localStorage.setItem(anonymousUserUnclaimedCartIdLocalStorageKey, unclaimedAnonymousCartId)
      }

      return {
        ...prevShoppingCartState,
        initialSyncComplete: true,
        cart,
        requirements,
      }
    })

  const setInProgressRequestById = React.useCallback(
    (id: string) =>
      setShoppingCartState((s) => {
        const inProgressCartRequestIds = new Set(s.inProgressCartRequestIds)
        if (inProgressCartRequestIds.has(id)) inProgressCartRequestIds.delete(id)
        else inProgressCartRequestIds.add(id)
        return { ...s, inProgressCartRequestIds }
      }),
    [setShoppingCartState]
  )

  const [realizeTosDataLazyQuery] = useRealizeTosDataLazyQuery()
  const [getCartLazyQuery] = useGetCartLazyQuery({
    onCompleted: (res) => {
      if (isAuthenticated) {
        localStorage.removeItem(anonymousUserUnclaimedCartIdLocalStorageKey)
      }

      const { get_cart, ...rest } = res
      processShoppingCartState(get_cart, rest)
    },
  })

  const [setPromoMutation] = useSetPromoMutation({
    onCompleted: (res) => {
      if (res.set_cart_promo.applied_promo_code) {
        analytics.track(`User applied promo '${res.set_cart_promo.applied_promo_code}'`)
        setSuccessToast('Promo applied.')
      } else {
        setSuccessToast('Error applying promo.')
      }
      processShoppingCartState(res.set_cart_promo)
    },
    onError: () => setSuccessToast('Error applying promo.'),
  })

  const setPromo = React.useCallback(
    async (promoCode: string) => {
      const anonymousUserUnclaimedCartId = getAnonCartId()

      const id = v4()
      setInProgressRequestById(id)
      return setPromoMutation({ variables: { promoCode, cartId: anonymousUserUnclaimedCartId } }).finally(() =>
        setInProgressRequestById(id)
      )
    },
    [setPromoMutation, setInProgressRequestById]
  )

  const [claimCart] = useClaimCartMutation({
    onError: (error) => {
      if (error.graphQLErrors.length) {
        localStorage.removeItem(anonymousUserUnclaimedCartIdLocalStorageKey)
      }
    },
    onCompleted: async (res) => {
      if (isAuthenticated) {
        localStorage.removeItem(anonymousUserUnclaimedCartIdLocalStorageKey)
      }
      const id = v4()
      setInProgressRequestById(id)

      const tos = await realizeTosDataLazyQuery()
        .then((x) => x.data)
        .finally(() => setInProgressRequestById(id))

      processShoppingCartState(res.claim_cart, tos)
    },
  })

  const initialCartQuery = React.useCallback(() => {
    const anonymousUserUnclaimedCartId = getAnonCartId()

    if (isAuthenticated && anonymousUserUnclaimedCartId) {
      return claimCart({ variables: { cartId: anonymousUserUnclaimedCartId } })
    } else {
      return getCartLazyQuery({ variables: { cartId: anonymousUserUnclaimedCartId, skipTos: !isAuthenticated } })
    }
  }, [isAuthenticated, claimCart, getCartLazyQuery])

  React.useEffect(() => {
    if (!authCheckComplete || !featureFlagsReady) {
      return
    }
    if (initialSyncComplete) return

    const id = v4()
    setInProgressRequestById(id)
    initialCartQuery().finally(() => setInProgressRequestById(id))
  }, [
    initialSyncComplete,
    setShoppingCartState,
    initialCartQuery,
    setInProgressRequestById,
    authCheckComplete,
    featureFlagsReady,
  ])

  const cartRef = useAutoUpdatingRef(shoppingCartState.cart)

  const [updateCartMutation] = useUpdateCartMutation({
    onCompleted: (res) => processShoppingCartState(res.update_cart),
    onError: () => {
      setErrorToast('Error updating cart.')
    },
  })
  const [emptyCart] = useEmptyCartMutation({
    onCompleted: (res) => processShoppingCartState(res.empty_cart),
    onError: () => {
      setErrorToast('Error clearing cart.')
    },
  })

  const updateInFlightChangesProductIdOrIds = React.useCallback(
    (productIdOrIds: string | string[]) => {
      const array = makeArray(productIdOrIds)
      setShoppingCartState((s) => {
        const isAdd = !s.inFlightChangesProductIds.some((x) => array.includes(x))
        const nextInFlightChangesProductIds = isAdd
          ? s.inFlightChangesProductIds.concat(productIdOrIds)
          : s.inFlightChangesProductIds.filter((x) => !array.includes(x))

        return { ...s, inFlightChangesProductIds: nextInFlightChangesProductIds }
      })
    },
    [setShoppingCartState]
  )

  const sharedFacebookConversionDataRef = useSharedFacebookConversionDataRef()

  const addProduct = React.useCallback(
    (
      stripeProductId: string,
      name: string | null,
      isCustomPackageTest?: boolean,
      mutationOptions?: Omit<UpdateCartMutationOptions, 'variables'>
    ) => {
      const current = cartRef.current.items[stripeProductId]?.quantity ?? 0
      const id = v4()

      const anonymousUserUnclaimedCartId = getAnonCartId()

      setInProgressRequestById(id)
      analytics.track('Product Added to Cart', { stripeId: stripeProductId, name })
      updateInFlightChangesProductIdOrIds(stripeProductId)

      const update = [{ id: stripeProductId, quantity: current + 1 }]

      const entries = isCustomPackageTest ? [] : update
      const customPackageTests = isCustomPackageTest ? update : []

      return updateCartMutation({
        variables: {
          entries,
          customPackageTests,
          cartId: anonymousUserUnclaimedCartId,
        },
        ...mutationOptions,
      })
        .then(() => {
          sendFacebookConversionEventFromClient({
            event_name: 'AddToCart',
            ...sharedFacebookConversionDataRef.current,
            custom_data: {
              currency: 'usd',
              contents: [
                {
                  id: stripeProductId,
                  quantity: 1,
                },
              ],
            },
          })
        })
        .finally(() => {
          updateInFlightChangesProductIdOrIds(stripeProductId)
          setInProgressRequestById(id)
        })
    },
    [
      cartRef,
      updateCartMutation,
      setInProgressRequestById,
      updateInFlightChangesProductIdOrIds,
      sharedFacebookConversionDataRef,
    ]
  )

  const setProductQuantity = React.useCallback(
    (input: SetQuantityData | SetQuantitySetterFunc) => {
      const changes = typeof input === 'function' ? input(cartRef.current) : input
      const id = v4()

      const anonymousUserUnclaimedCartId = getAnonCartId()

      setInProgressRequestById(id)
      if (changes.quantity === 0) {
        analytics.track('Product Removed from Cart', { stripeId: changes.productId, name: changes.name })
      }
      updateInFlightChangesProductIdOrIds(changes.productId)

      const update = [{ id: changes.productId, quantity: changes.quantity }]

      const entries = changes.isCustomPackageTest ? [] : update
      const customPackageTests = changes.isCustomPackageTest ? update : []
      return updateCartMutation({
        variables: {
          entries,
          customPackageTests,
          cartId: anonymousUserUnclaimedCartId,
        },
      }).finally(() => {
        updateInFlightChangesProductIdOrIds(changes.productId)
        setInProgressRequestById(id)
      })
    },
    [cartRef, updateCartMutation, setInProgressRequestById, updateInFlightChangesProductIdOrIds]
  )

  type UpdateCartEntry = {
    stripeProductId: string
    name: string | null
    isCustomPackageTest?: boolean
    quantity: number
  }
  const updateCart = React.useCallback(
    (input: UpdateCartEntry[]) => {
      const id = v4()
      const anonymousUserUnclaimedCartId = getAnonCartId()

      const inFlightChangesProductIdOrId: string[] = []
      setInProgressRequestById(id)

      const result = input.reduce(
        (acc, { stripeProductId, name, isCustomPackageTest, quantity }) => {
          inFlightChangesProductIdOrId.push(stripeProductId)

          const previousQuantity = cartRef.current.items[stripeProductId]?.quantity ?? 0

          if (previousQuantity && !quantity) {
            analytics.track('Product Removed from Cart', { stripeId: stripeProductId, name })
          } else if (quantity && !previousQuantity) {
            analytics.track('Product Added to Cart', { stripeId: stripeProductId, name })
          } else {
            analytics.track('Product quantity updated', {
              stripeId: stripeProductId,
              name,
              fromQuantity: previousQuantity,
              toQuantity: quantity,
            })
          }
          const update = [{ id: stripeProductId, quantity }]

          const entries = isCustomPackageTest ? [] : update
          const customPackageTests = isCustomPackageTest ? update : []
          return {
            entries: acc.entries.concat(entries),
            customPackageTests: acc.customPackageTests.concat(customPackageTests),
          }
        },
        {
          entries: [],
          customPackageTests: [],
        } as { entries: { id: string; quantity: number }[]; customPackageTests: { id: string; quantity: number }[] }
      )

      updateInFlightChangesProductIdOrIds(inFlightChangesProductIdOrId)

      return updateCartMutation({
        variables: {
          ...result,
          cartId: anonymousUserUnclaimedCartId,
        },
      }).finally(() => {
        updateInFlightChangesProductIdOrIds(inFlightChangesProductIdOrId)
        setInProgressRequestById(id)
      })
    },
    [updateCartMutation, cartRef, setInProgressRequestById, updateInFlightChangesProductIdOrIds]
  )

  const removeProduct = React.useCallback(
    (stripeProductId: string, name: string | null, isCustomPackageTest?: boolean) => {
      const id = v4()
      const anonymousUserUnclaimedCartId = getAnonCartId()

      setInProgressRequestById(id)
      analytics.track('Product Removed from Cart', { stripeId: stripeProductId, name })
      updateInFlightChangesProductIdOrIds(stripeProductId)

      const update = [{ id: stripeProductId, quantity: 0 }]

      const entries = isCustomPackageTest ? [] : update
      const customPackageTests = isCustomPackageTest ? update : []

      return updateCartMutation({
        variables: {
          entries,
          customPackageTests,
          cartId: anonymousUserUnclaimedCartId,
        },
      }).finally(() => {
        updateInFlightChangesProductIdOrIds(stripeProductId)
        setInProgressRequestById(id)
      })
    },
    [updateCartMutation, setInProgressRequestById, updateInFlightChangesProductIdOrIds]
  )

  const clearCart = React.useCallback(() => {
    const id = v4()

    const anonymousUserUnclaimedCartId = getAnonCartId()
    setInProgressRequestById(id)
    analytics.track('Cart Cleared')
    return emptyCart({ variables: { cartId: anonymousUserUnclaimedCartId } }).finally(() =>
      setInProgressRequestById(id)
    )
  }, [emptyCart, setInProgressRequestById])

  const loading = shoppingCartState.inProgressCartRequestIds.size > 0 || !initialSyncComplete

  const refetchCart = React.useCallback(() => {
    const id = v4()
    const anonymousUserUnclaimedCartId = getAnonCartId()

    setInProgressRequestById(id)
    return getCartLazyQuery({ variables: { cartId: anonymousUserUnclaimedCartId, skipTos: !isAuthenticated } }).finally(
      () => setInProgressRequestById(id)
    )
  }, [getCartLazyQuery, setInProgressRequestById, isAuthenticated])

  return {
    ...shoppingCartState,
    loading,
    addProduct,
    removeProduct,
    clearCart,
    requirements: shoppingCartState.requirements,
    setShoppingCartState,
    refetchCart,
    setProductQuantity,
    setPromo,
    updateCart,
  }
}
