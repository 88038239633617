import { useFeature } from '@optimizely/react-sdk' // eslint-disable-line no-restricted-imports
import { featureFlags } from '../FeatureFlagProvider'
import { useAuth0 } from '@auth0/auth0-react' // eslint-disable-line no-restricted-imports

type UseFeatureParams = Parameters<typeof useFeature>

const defaultReturn = [false, {}, false, false, false] as const

/**
 * wrapper for optimizely feature flag hook:
 *  1) sets auto update to true so that when a flag value changes (such as after logging in), the flag sends out the
 *     updated value
 *  2) hooks into our tracked jotai state that allows us to determine when the flags are ready, and aren't just false
 *     because they have not been updated yet, which allows us to prevent things like redirecting from a disabled page
 *     incorrectly because it was originally false, then the user logged in, but even though they are authed the new
 *     flag ahs not come back yet
 *  3) switches reh return from an array to an objcet for selective and named destructuring
 */
export function useFeatureFlag(
  featureFlag: featureFlags,
  hookOptions?: UseFeatureParams[1],
  overrideOptions?: UseFeatureParams[2]
) {
  const { isLoading } = useAuth0()

  const [isEnabled, vars, clientReady, didTimeout] =
    typeof window === 'undefined'
      ? defaultReturn
      : /* not conditional in reality since server check will never change in a single env context. this errors sometimes on server and also sends clientReady as true on server but immediately false on client, which could cause hydration mismatches. if we want the actual values available on server, should rewrite this to fetch the data file in a server component and pass the value in immediately so everything is known at mount on both client and server) */
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useFeature(featureFlag, { autoUpdate: true, ...hookOptions }, overrideOptions)

  return { isEnabled, vars, clientReady, didTimeout, featureFlagsReady: clientReady && !isLoading }
}
