import * as React from 'react'
import { StandardModalCard, modalCloseXClassName } from '../atoms/StandardModalCard'

import { CrossDerivative } from '../../../assets/components/CrossDerivative'
import { useSetModal } from '../modalState'
import styled from '@emotion/styled'
import { theme } from '../../../styles/theme'
import { CssProp } from '../../../typescript/emotion'

export const ModalCloseIcon = styled(CrossDerivative)`
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
`

type Props = React.PropsWithChildren<{
  id?: string
  className?: string
  isClosable?: boolean
  onClose?: () => void
  closeIconFillColor?: string
  cssProp?: CssProp
  preserveModalStateOnClose?: boolean
}>

export function StandardModalContainer({
  children,
  className,
  isClosable = true,
  onClose,
  closeIconFillColor = theme.colors.grayscale900,
  cssProp,
  id,
  preserveModalStateOnClose,
}: Props) {
  const setModal = useSetModal()
  return (
    <StandardModalCard className={className} css={cssProp} id={id}>
      {!!isClosable && (
        <ModalCloseIcon
          onClick={() => {
            onClose?.()
            if (!preserveModalStateOnClose) setModal()
          }}
          className={modalCloseXClassName}
          color={closeIconFillColor}
        />
      )}
      {children}
    </StandardModalCard>
  )
}
